<template>
  <Popup
    title="채용공고 미리보기"
    :maxWidth="1000"
    closeBtnText="닫기"
    @onClickClose="$emit('onClickClose')">
    <TableView>
      <template v-slot:tbl_colgroup>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>목록</th>
        </tr>
        <tr>
          <td>
            <BoardItem
              :itemData="detailDataSync"/>
          </td>
        </tr>
        <tr>
          <th>상세</th>
        </tr>
        <tr>
          <td>
            <BoardView
              :detailData="detailDataSync"
              :isThumbnail="true"
              :isShare="false"
              :isDate="true"/>
          </td>
        </tr>
      </template>
    </TableView>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import BoardItem from '@/components/common/landingPagePreview/BoardItem'
import BoardView from '@/components/common/landingPagePreview/BoardView'
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';

export default {
  name:'RecruitPreviewPopup',
  props:{
    detailData: Object,
  },
  components:{
    Popup,
    TableView,
    BoardItem,
    BoardView,
    Button
  },
  mounted(){
    const detailDataSync = JSON.parse(JSON.stringify(this.detailData));
    this.detailDataSync = detailDataSync;
    if(!detailDataSync.createdAt){
    this.detailDataSync.createdAt = new Date();
    }
    if(detailDataSync.thumbnail.length > 0){
      this.detailDataSync.thumbnail = detailDataSync.thumbnail[0].src;
    }else{
      this.detailDataSync.thumbnail = ''
    }
  },
  data(){
    return{
      detailDataSync:{}
    }
  }
}
</script>
<style scoped>
.tbl_view::v-deep td{background:repeating-linear-gradient(45deg, #f9f9f9, #f9f9f9 10px, transparent 0, transparent 20px)}
.lemontree_layer::v-deep .item_board{width:100%}
.lemontree_layer::v-deep .view_comm{padding:20px;background-color:#fff}
@media (prefers-color-scheme: dark) {
  .tbl_view::v-deep td{background:repeating-linear-gradient(45deg, #222, #222 10px, transparent 0, transparent 20px)}
  .lemontree_layer::v-deep .tit_view{color:#fff}
  .lemontree_layer::v-deep .view_comm{background-color:#222}
}
</style>