import RecruitModel from '@/views/landing/recruit/model/RecruitModel';
import { pageValidated } from '@lemontree-ai/lemontree-admin-common-front/utils/validateUtils'
import { GET_REQUEST, POST_REQUEST, PUT_REQUEST, DELETE_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import router from '@/router';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';

export default class RecruitDetailViewModel {
  constructor() {
    this.model = {};
    this.isModify = false;
    this.registerValid = {
      // date:{
      //   value: 'date',
      //   valueType:'',
      //   isValid:false,
      // },
      thumbnail:{
        value: 'thumbnail',
        valueType:'array',
        isValid:false,
      },
      shareThumbnail:{
        value: 'shareThumbnail',
        valueType:'array',
        isValid:false,
      },
      title:{
        value: 'title',
        valueType:'',
        isValid:false,
      },
      contents:{
        value: 'contents',
        valueType:'',
        isValid:false,
      },
    },
    this.isRecruitPreviewPopup = false;
  }

  init(id){
    this.model = new RecruitModel();
    if(id){
      this.isModify = true;
      this.getRecruitDetail(id);
    }
  }
  onClickRecruitPreviewPopup(){
    this.isRecruitPreviewPopup = true;
  }
  onClickCloseRecruitPreviewPopup(){
    this.isRecruitPreviewPopup = false;
  }

  registerValidatedResult(){
    return pageValidated(this.registerValid, this.model)
  }

  onClickDelete(){
    const requestDelete = () => {
      this.deleteRecruitDetail();
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `채용공고를 정말 삭제하시겠습니까?`,
      closeBtnText: '취소',
      compeleteBtnText: '확인',
      confirmCallBack: requestDelete,
    });
  }
  onCompleteFileSave(){
    if(this.isModify){
      this.putRecruitDetail();
    }else{
      this.postRecruitDetail();
    }
  }
  // 상세
  getRecruitDetail(id){
    const path = `${apiPath.RECRUIT_DETAIL.format(id)}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.model.setData(resultData);
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // 등록
  postRecruitDetail(){
    const path = `${apiPath.RECRUIT_REGISTER}`;
    const data = this.model.getRegisterData();
    POST_REQUEST(path, data).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '채용공고가 등록되었습니다');
      router.push({ name: 'LANDING_RECRUIT_LIST' });
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // 수정
  putRecruitDetail(){
    const path = `${apiPath.RECRUIT_MODIFY.format(this.model.id)}`;
    const data = this.model.getModifyData();
    // console.log(data);
    PUT_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '채용공고가 수정되었습니다');
      router.push({ name: 'LANDING_RECRUIT_DETAIL' });
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // 삭제
  deleteRecruitDetail(){
    const path = `${apiPath.RECRUIT_DELETE.format(this.model.id)}`;
    DELETE_REQUEST(path).then(
    (success) => {
      store.dispatch('commonToast/fetchToastStart', '채용공고가 삭제되었습니다');
      router.push({ name: 'LANDING_RECRUIT_LIST' });
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}