export default class RecruitModel {
  constructor(){
    this.objRaw = {};
    this.id = '';
    this.description = '';

    this.status = 'HIDDEN';
    this.title = '';
    this.contents = '';
    this.thumbnail = [];
    this.shareThumbnail = [];
    this.createdAt = '';
    this.updatedAt = '';
  }
  setData(obj){
    if( obj ) this.objRaw = obj;
    const { id, status, thumbnail, shareThumbnail, title, description, contents, createdAt, updatedAt } = obj;
    this.id = id;
    this.status = status;
    if(thumbnail){
      this.thumbnail = [
        {
          src : thumbnail,
        }
      ]
    }
    if(shareThumbnail){
      this.shareThumbnail = [
        {
          src : shareThumbnail,
        }
      ]
    }
    this.title = title;
    this.description = description;
    this.contents = contents;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
  getRegisterData(){
    let obj = {
      status : this.status,
      thumbnail : this.thumbnail.length > 0 ? this.thumbnail[0].uploadedSrc : '',
      shareThumbnail : this.shareThumbnail.length > 0 ? this.shareThumbnail[0].uploadedSrc : '',
      title : this.title,
      description : this.description,
      contents : this.contents,
    }
    return obj;
  }
  getModifyData(){
    let obj = {
      status : this.status,
      title : this.title,
      thumbnail : this.thumbnail.length > 0 ? this.thumbnail[0].uploadedSrc : '',
      shareThumbnail : this.shareThumbnail.length > 0 ? this.shareThumbnail[0].uploadedSrc : '',
      description : this.description,
      contents : this.contents,
    }
    return obj;
  }
}